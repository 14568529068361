<template>
  <v-app>
    <div>
      <v-alert
        v-model="alert"
        border="left"
        dark
        dismissible
        class="alert"
        :class="{
          'alert-error': alertType == 'error',
          'alert-success': alertType == 'success'
        }"
      >
        {{ alertMessage }}
      </v-alert>
    </div>
    <div class="row">
      <div class="col-md-6 ml-auto mr-auto">
         <div class="mb-8 mt-8 text-center">
          <h1>Holiday</h1>
        </div>

        <v-form ref="holiday" v-model="valid">
          <v-row>
            <v-col >
              <TextField v-model="formData.Name" :label="'Name'" />
            </v-col>
            <v-col class="col-md-12">
              <TextField
                v-model="formData.Date"
                :type="'date'"
                :label="'Date'"
              />
            </v-col>
            <v-col class="col-md-7 mb-4" >
              <v-radio-group v-model="formData.isNational" :mandatory="false" class="radiogroup" :column="false" :dense="true" :validate-on-blur="true" label="National ?">
                <v-radio label="Yes" :value="true"></v-radio>
                <v-radio label="No" :value="false"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col v-if="formData.isNational === false" class="col-md-12" dense>
              <v-select
                :items="states"
                dense
                label="State"
                multiple
                v-model="formData.state"
                item-text="name"
                item-value="id"
                outlined
              >

              </v-select>
            </v-col>
             <v-col cols="12" class="d-flex justify-end">

                      <Button
                      :label="'Submit'"
                      :btnType="'Submit'"
                      @onClick="dialog = true"
                      :disabled="!valid"
                      :isLoading="isLoading"
                      class="mr-4"
                    />

                    <Button :label="'Reset'" :btnType="'Cancel'" @onClick="onReset" />

                  </v-col>
          </v-row>
        </v-form>
      </div>
    </div>
     <ConfirmationDialog :title="'Are you sure you want to submit?'" :isLoading="isLoading" :dialog="dialog" :btnTitle="'Yes'" @close="dialog = false" @btnAction="onSave"/>
  </v-app>
</template>
<script>
import TextField from '@/components/ui/form/TextField.vue'
import Button from '@/components/ui/form/Button.vue'
import ConfirmationDialog from '@/components/ui/confirmationDialog.vue'
import { stateService, holidayService } from '@/services'
export default {
  components: {
    TextField,
    Button,
    ConfirmationDialog
  },
  data () {
    return {
      dialog: false,
      alertMessage: '',
      alert: false,
      alertType: '',
      valid: false,
      isLoading: false,
      states: [],
      formData: {
        Date: '',
        StateId: '',
        isNational: null,
        Name: '',
        state: ''
      }
    }
  },
  methods: {
    showAlertMessage (message, type) {
      this.alertMessage = message
      this.alert = true
      this.alertType = type
    },
    onSave () {
      let data = null
      if (this.formData.isNational === true) {
        data = {
          DateOfHoliday: this.formData.Date,
          isNational: this.formData.isNational,
          Name: this.formData.Name
        }
      } else {
        data = {
          DateOfHoliday: this.formData.Date,
          isNational: this.formData.isNational,
          Name: this.formData.Name,
          StateId: this.formData.state
        }
      }
      this.isLoading = true
      holidayService.createHoliday(data).then(() => {
        this.showAlertMessage('Holiday created', 'success')
      }).catch((error) => {
        this.isLoading = false
        console.log(error)
        this.showAlertMessage('Holiday creation failed', 'error')
      }).finally(() => {
        this.isLoading = false
        this.dialog = false
        this.onReset()
      })
    },
    onReset () {
      this.$refs.holiday.reset()
    }
  },
  mounted () {
    stateService.getAllStates().then(result => {
      this.states = result.data.items
    }).catch(error => {
      console.log(error)
    })
  }
}
</script>
<style scoped>
.alert {
  position: fixed;
  z-index: 9999;
  top: 5px;
  min-width: 50%;
}
.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.v-input--selection-controls{
  margin: 0;
  padding: 0;
}
</style>
